import React, { Suspense, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import * as analytics from "./analytics";
import { GlobalStyles } from "./GlobalStyle";
import { getUserData, isLoggedIn } from "./services/auth.service";
import { useDarkMode } from "./services/useDark.service";
import { darkTheme, lightTheme } from "./Theme";

import "./i18n";
import "./mixr.css";

analytics.init()


const MIXRRoom = React.lazy(() => import("./components/_MIXRRoom/MIXRRoom"));
const Footer = React.lazy(() => import("./components/_views/footer"));
const Signup = React.lazy(() => import("./components/_login/signup"));
const Reset = React.lazy(() => import("./components/_login/reset"));
const Features = React.lazy(() => import("./components/_features/features"));
const PrivacyPolicy = React.lazy(() => import("./components/_privacyPolicy/privacyPolicy"));
const Terms = React.lazy(() => import("./components/_terms/terms"));
const Home = React.lazy(() => import("./components/_home/home"));
const Login = React.lazy(() => import("./components/_login/login"));
const Menu = React.lazy(() => import("./components/_views/menu"));

function Mixr() {
  const [theme, themeToggler] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;
  var customStyles = {};
  if (theme == "light") {
    customStyles = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 10,
      },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "5rem",
        padding: "0px",
        zIndex: 10,
      },
    };
  } else {
    customStyles = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(24, 25, 25, 0.75)",
        zIndex: 10,
      },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#262527",
        borderRadius: "5rem",
        padding: "0px",
        zIndex: 10,
      },
    };
  }


  const modalRef = useRef(null);

  const modal = (component) => {
    modalRef.current.modal(component);
  };

  const validateUser = () => { 
    let u = getUserData();
    analytics.identify(u._id,u.name,u.email)
    setLoggedIn(isLoggedIn()); 
  };
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [OV, setOV] = useState(undefined);
  const [OVSession, setOVSession] = useState(undefined);
  const [OVPublisher, setOVPublisher] = useState(undefined);
  const [joinWith, setJoinWith] = useState({ audio: true, video: true });
  const [selectedIO, setSelectedIO] = useState(undefined);
  const [roomData, setRoomData] = useState({});

  const updateOV = (input) => setOV(input);
  const updateOVSession = (input) => setOVSession(input);
  const updateOVPublisher = (input) => setOVPublisher(input);
  const updateJoinWith = (input) => setJoinWith(input);
  const updateSelectedIO = (input) => setSelectedIO(input);
  const updateRoomData = (input) => setRoomData(prevState => ({ ...prevState, ...input }));
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(undefined);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    if(!!OV){
      OV.enableProdMode()
    }
  },[OV])


  useEffect(() => {
    if (!modalIsOpen) {
      setModalComponent(undefined);
    }
  }, [modalIsOpen]);
  useEffect(() => {
    if (!!modalComponent) {
      setModalIsOpen(true);
    }
  }, [modalComponent]);
  useEffect(() => {
    var test = document.createElement("div");
    test.innerHTML = "&nbsp;";
    test.className = "adsbox";
    document.body.appendChild(test);
    window.setTimeout(function () {
      if (test.offsetHeight === 0) {
        document.body.classList.add("adblock");
        analytics.event("adBlock")
      }
      test.remove();
    }, 400);
    analytics.pageview(window.host + window.pathname);
  }, []);


  const customStylesRoom = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(54, 53, 55, 0.75)",
      zIndex: 6969,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      color: "#ffffff",
      backgroundColor: "#000",
      borderRadius: "3rem",
      padding: "0",
      border: "0.1rem solid #fff",
    },
  };


  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        <div className="parent" id="page-container">
          <Suspense fallback={null}>
            <Router>
              {(!roomData.eventId && !roomData.houseId) && (
                <Menu
                  validateUser={validateUser}
                  loggedIn={loggedIn}
                  customStyles={customStyles}
                  customStylesRoom={customStylesRoom}
                  closeModal={closeModal}
                  setModalComponent={setModalComponent}
                />
              )}
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStylesRoom}
              >
                {modalComponent}
              </Modal>

              <Switch>
                <Route path="/login">
                  {loggedIn && !window.location.toString().includes("houseId") ? (
                    <Redirect to={"/mymixrs"} />
                  ) : (
                    <div className="blaze">
                      <Login validateUser={validateUser} loggedIn={loggedIn} analytics={analytics} />
                    </div>
                  )}
                </Route>
                <Route path="/signup">
                  {loggedIn ? (
                    <Redirect to={"/mymixrs"} />
                  ) : (
                    <div className="blaze">
                      <Signup validateUser={validateUser} loggedIn={loggedIn} analytics={analytics} />
                    </div>
                  )}
                </Route>
                <Route path="/reset">
                  {loggedIn ? (
                    <Redirect to={"/mymixrs"} />
                  ) : (
                    <div className="blaze">
                      <Reset validateUser={validateUser} loggedIn={loggedIn} />
                    </div>
                  )}
                </Route>

                <Route path="/privacy">
                  <div className="blaze">
                    <PrivacyPolicy theme={theme} />
                  </div>
                </Route>
                <Route path="/terms">
                  <div className="blaze">
                    <Terms theme={theme} />
                  </div>
                </Route>
                <Route path="/join">
                  <MIXRRoom
                    closeModal={closeModal}
                    setModalComponent={setModalComponent}
                    loggedIn={loggedIn}
                    OV={OV}
                    roomData={roomData}
                    setRoomData={setRoomData}
                    validateUser={validateUser}
                    OVSession={OVSession}
                    joinWith={joinWith}
                    selectedIO={selectedIO}
                    OVPublisher={OVPublisher}
                    updateOV={updateOV}
                    updateOVSession={updateOVSession}
                    updateOVPublisher={updateOVPublisher}
                    updateJoinWith={updateJoinWith}
                    updateSelectedIO={updateSelectedIO}
                    updateRoomData={updateRoomData}
                    analytics={analytics}
                    customStyles={customStyles}
                  />
                </Route>
                <Route path="/mymixrs">
                  <div className="blaze">
                    <Home
                      customStyles={customStyles}
                      validateUser={validateUser}
                      loggedIn={loggedIn} analytics={analytics}
                    />
                  </div>
                </Route><Route path="/">
                  <div className="blaze">
                    <Features theme={theme} />
                  </div>
                </Route>
              </Switch>
              {(!roomData.eventId && !roomData.houseId) && (
                <Footer
                  themeToggler={themeToggler}
                  themeToggler={themeToggler}
                  closeModal={closeModal}
                  customStyles={customStyles}
                  theme={theme}
                  id="footer"
                />
              )}
            </Router>
          </Suspense>
        </div>
      </>
    </ThemeProvider>
  );
}

export default Mixr;
