export const lightTheme = {
    body: '#FFF',
    text: '#363537',
    toggleBorder: '#FFF',
    background: '#ffffff',
    highlight: '#dedede',
    backdrop: '#222222ee',
    border: '#aaaaaa',
    blurBack: '#ededed88',
}
export const darkTheme = {
    body: '#262527',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: '#262527',
    highlight: '#262626',
    backdrop: '#111111ee',
    border: '#777777',
    blurBack: '#18181888',
}