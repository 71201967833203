const API_URL = process.env.REACT_APP_API;
const LOGIN_URL = `${API_URL}/user/login`;
const REFRESH_URL = `${API_URL}/user/refresh/`;
const LOGOUT_URL = `${API_URL}/user/logout`;
const REQUEST_RESET_URL = `${API_URL}/user/resetPassword`;
const RESET_URL = `${API_URL}/user/reset`;
const SIGNUP_URL = `${API_URL}/user/`;
const LOCALSTORAGE_USER_KEY = 'user';
const USER_SAVE_URL = `${API_URL}/user/update`;
const EVENTS_REMOVE_URL = `${API_URL}/event/remove`;
const EVENTS_ADD_URL = `${API_URL}/event/add`;
const EVENTS_NEW_URL = `${API_URL}/event/`;
const EVENTS_URL = `${API_URL}/event/`;
// const EVENTS_URL = `https://beta.mixronline.com/api/event/`;
const EVENTS_HOUSE_URL = `${API_URL}/event/house`;
const HOUSE_REMOVE_URL = `${API_URL}/event/house/remove`;
const HOUSE_ADD_URL = `${API_URL}/event/house/add`

const randomString = (length, chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') => {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

const getUser = () => JSON.parse(localStorage.getItem(LOCALSTORAGE_USER_KEY));
const setUser = (input) => localStorage.setItem(LOCALSTORAGE_USER_KEY, JSON.stringify(input));
const removeUser = () => localStorage.removeItem(LOCALSTORAGE_USER_KEY);
const validateUserObject = (data) => !!(data && data._id && data.email)
export const isLoggedIn = () => validateUserObject((getUser()));
export const getUserData = () => {
    if (isLoggedIn()) {

        let u = getUser();

        if (u._id == "6007ea0b47a4067154e9792e") {
            let newId = localStorage.getItem("GuestId")
            if (!!newId) {

                u._id = newId;
            } else {

                newId = randomString(30)
                u._id = newId;
                localStorage.setItem("GuestId", newId)
            }

            return u;
        } else {
            return u;
        }


    } else {
        return false
    }
}

const storeUserObject = (data) => {
    if (validateUserObject(data)) {
        const { _id, name, email, invalidEmail } = data;
        if (getUser() !== data)
            setUser({ _id, name, email, invalidEmail });
        return true;
    } else {
        removeUser();
        return false;
    }
}

export const loginService = async ({ email, password }) => {
    try {
        if (!email || !password) return false;
        const response = await fetch(LOGIN_URL, {
            method: "POST",
            headers: { "Content-Type": "application/json", },
            credentials: "include",
            body: JSON.stringify({ email, password })
        })
        const json = await response.json();
        if (json.hasOwnProperty('user')) return storeUserObject(json.user);
        // todo: fix API to not return string.
        else if (json.hasOwnProperty('errors') && json.errors.reset === 'reset active') return 'reset';
    } catch (error) {
        removeUser();
        return false;
    }
}

export const authService = async () => {
    try {
        const response = await fetch(REFRESH_URL, { credentials: "include" })
        const json = await response.json();
        return storeUserObject(json.user);
    } catch (error) {
        removeUser();
        return false;
    }
}

export const logoutService = async () => {
    //todo: to be fixed -- logout does not work.
    removeUser();
    try {

        const response = await fetch(LOGOUT_URL, { credentials: "include" })
        const json = await response.json();
        return true;
    } catch (error) {
        return false;
    } finally {
        removeUser();
    }
}


export const requestResetPasswordService = async ({ email }) => {
    try {
        if (!email) return false;
        const response = await fetch(REQUEST_RESET_URL, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email }),
        })
        const text = await response.text();
        return text;
    } catch (error) {

        return false;
    }
}

export const signUpService = async ({ name, email, password }) => {
    try {
        if (password.length >= 7 && !!name && !!email && !!password) {
            const response = await fetch(SIGNUP_URL, {
                method: "POST",
                headers: { "Content-Type": "application/json", },
                body: JSON.stringify({ name, email, password })
            })
            const json = await response.json();
            if (json.hasOwnProperty('user')) {
                storeUserObject(json.user);
                return 'Account has been activated!';
            }
            if (json.hasOwnProperty('errors')) {
                if (json.errors.email === "already exists") {
                    return "email already exists"
                }
            }
        }
        return false;
    } catch (error) {

        return false;
    }
}

export const resetPasswordService = async ({ email, guid, password }) => {
    try {
        if (!!email && !!guid && !!password && password.length >= 7) {
            const response = await fetch(RESET_URL,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json", },
                    body: JSON.stringify({ email, GUID: guid, newPassword: password }),
                });
            const json = await response.json();
            if (json.errors === "password updated") return true;
            return false;
        }
    } catch (error) {

        return false;
    }
}

export const saveUser = async (user) => {
    storeUserObject(user)
    let res = await fetch(USER_SAVE_URL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        redirect: "follow", // manual, *follow, error
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(user)
    })
    if(res.status == 200){
        return true
    }else{
        return false
    }
}

export const getEventsService = async () => {

    try {
        const response = await fetch(EVENTS_URL, {
            credentials: "include",
        })
        const json = await response.json();
        return (json);
    } catch (error) {
        return false;
    }
}

export const removeMixr = async (id) => {

    try {
        const response = await fetch(EVENTS_REMOVE_URL, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ _id: id })
        })
        const json = await response.json();
        //
        return (json);
    } catch (error) {
        return false;
    }
}

export const removeH = async (id) => {

    try {
        const response = await fetch(HOUSE_REMOVE_URL, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ _id: id })
        })
        const json = await response.json();
        //
        return (json);
    } catch (error) {
        return false;
    }
}

export const addHouse1 = async (id) => {

    try {
        const response = await fetch(HOUSE_ADD_URL, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ _id: id })
        })
        const json = await response.json();
        //
        return (json);
    } catch (error) {
        return false;
    }
}

export const addMixr = async (id) => {

    try {
        const response = await fetch(EVENTS_ADD_URL, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ _id: id })
        })
        const json = await response.json();
        //
        return (json);
    } catch (error) {
        return false;
    }
}

export const newEvent = async (event) => {
    try {
        const response = await fetch(EVENTS_NEW_URL, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ event: event })
        })
        const json = await response.json();
        //
        return (json);
    } catch (error) {

        return false;
    }
}

export const newHouse = async (event) => {
    try {
        const response = await fetch(EVENTS_HOUSE_URL, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ event: event })
        })
        const json = await response.json();
        //
        return (json);
    } catch (error) {

        return false;
    }
}