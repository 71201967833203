import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.1s linear;
  }
  button{
    background-color:  ${({ theme }) => theme.body};
    color:${({ theme }) => theme.text};
  }

  a {
    color:${({ theme }) => theme.text};
  }

  .footerOuter{
    transition: background-color 0.1s linear;
  }

  .blaze input{
    border: solid 1px ${({ theme }) => theme.text};
  }

  .popup{
    background-color:  ${({ theme }) => theme.body};
    color:${({ theme }) => theme.text};
    border-color:${({ theme }) => theme.text};
  }

  .rc-menu{
    background-color:  ${({ theme }) => theme.body} !important;
    color:${({ theme }) => theme.text} !important;
    border: solid 1px ${({ theme }) => theme.text} !important;
    border-radius: 10px;
  }

  .rc-menu__item{
    background-color:  ${({ theme }) => theme.body} !important;
    color:${({ theme }) => theme.text} !important;
  }

  .rc-menu__item--hover {
    background-color: ${({ theme }) => theme.highlight} !important;
  }

  .ReactModal__Overlay ReactModal__Overlay--after-open{
    background-color:  ${({ theme }) => theme.body} !important;
  }

  input{
    border: solid 1px ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.text};
    text-align: left;
  }

  .showPass path{
    color: ${({ theme }) => theme.text};
    fill: ${({ theme }) => theme.text};
  }

  .iMixr{
    background-color: ${({ theme }) => theme.backdrop};
  }
  
  .nmBlur{
    background-color: ${({ theme }) => theme.backdrop};
  }

  .menuOuter {
    border-bottom: 1px solid ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.blurBack};
  }

  .footer{
    border-top: 1px solid ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.blurBack};
  }

  .downArrow path {
    fill: ${({ theme }) => theme.text};
  }

    `;
