import posthog from 'posthog-js'
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";


export const init = () => {
  posthog.init(process.env.REACT_APP_POSTHOG_API_ID, { api_host: process.env.REACT_APP_POSTHOG_API, autocapture: false })


  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing(),new posthog.SentryIntegration(posthog, 'http://sentry.mixronline.com/', '2')],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    tracesSampleRate: 1.0,
  });
}

export const sentryLog = (log) => {
  Sentry.captureMessage(log);
}

export const sentryError = (err) => {
  try {
    Sentry.captureException(err);
  } catch (error) {
    console.log(error)
  }
  
}

export const GA_TRACKING_ID = process.env.GTAG_ID


// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,

  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (event_name, event_properties) => {
  switch (process.env.REACT_APP_ANALYTICS_SERVICE) {
    case "google":
      window.gtag('event', event_name, event_properties)
      break;
    case "posthog":
      posthog.capture(event_name, event_properties)
      break;
    default:
  }

}

export const identify = (id, name, email) => {
  // if()
  posthog.identify(id, { name: name }, { email: email })
}

export const reset_user = () => {
  posthog.reset()
}

